import React from 'react';

import ClubLink from 'root/client/components/club-link/club-link';
import Expander from 'root/client/components/expander/expander';
import SalesLayout from 'root/client/components/sales-layout/sales-layout';

import type {
  DropInSelectClub as Props,
  Clubs as ClubsProps,
} from './drop-in-select-club.types';

const Clubs: React.FC<ClubsProps> = ({ clubs }) => (
  <div className="drop-in-select-club__clubs">
    {clubs.map(club => (
      <ClubLink {...club} key={club.href} />
    ))}
  </div>
);

// eslint-disable-next-line react/no-multi-comp
const DropInSelectClub: React.FC<Props> = ({ clubs, layout }) => (
  <SalesLayout {...layout}>
    {clubs.items.length ? (
      <div className="drop-in-select-club">
        <Expander {...clubs} itemRenderer={Clubs} standalone />
      </div>
    ) : null}
  </SalesLayout>
);

export default DropInSelectClub;
