import React from 'react';

import CleanHeader from 'root/client/components/clean-header/clean-header';
import Spinner from 'root/client/components/spinner/spinner';
import Text from 'root/client/components/text/text';

import type { CheckoutStatus as Props } from './checkout-status.types';

const CheckoutStatus: React.FunctionComponent<Props> = ({
  header,
  information,
}) => (
  <div className="checkout-status">
    <CleanHeader {...header} />
    <div className="checkout-status__content">
      <div className="checkout-status__processing-wrapper" data-testId="status">
        <div aria-hidden className="checkout-payment__loader">
          <Spinner />
        </div>
        <Text size={Text.sizes.large}>{information}</Text>
      </div>
    </div>
  </div>
);

export default CheckoutStatus;
