import CheckBox36 from '@sats-group/icons/36/checkbox';
import React from 'react';

import CleanHeader from '../../components/clean-header/clean-header';
import Message from '../../components/message/message';
import Price from '../../components/price/price';
import Text from '../../components/text/text';

import type { DropInConfirmation as Props } from './drop-in-confirmation.types';

const DropInConfirmation: React.FC<Props> = ({
  header,
  information,
  messages,
  summary,
  title,
}) => (
  <div className="drop-in-confirmation">
    <CleanHeader {...header} />
    <div className="drop-in-confirmation__title-wrapper">
      <div className="drop-in-confirmation__icon">
        <CheckBox36 />
      </div>
      <Text
        className="drop-in-confirmation__title"
        theme={Text.themes.emphasis}
        size={Text.sizes.header2}
        variant={Text.variants.hero}
      >
        {title}
      </Text>
    </div>
    <div className="drop-in-confirmation__messages">
      {messages.map(entry => (
        <div key={entry.text}>
          <Message {...entry} />
        </div>
      ))}
    </div>
    <div className="drop-in-confirmation__content">
      {information ? (
        <div className="drop-in-confirmation__information">
          <Text>{information}</Text>
        </div>
      ) : null}
      {summary ? (
        <div className="drop-in-payment__summary-wrapper">
          <div
            className="drop-in-confirmation__summary"
            data-testId="confirmation-summary"
          >
            <div className="drop-in-confirmation__summary-rows">
              {summary.rows.map(({ label, value }) => (
                <div className="drop-in-confirmation__summary-row">
                  <Text>{label}</Text>
                  <Text theme={Text.themes.medium}>{value}</Text>
                </div>
              ))}
            </div>
            <div className="drop-in-confirmation__summary-price">
              <Text>{summary.priceLabel}</Text>
              <Price {...summary.price} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

export default DropInConfirmation;
