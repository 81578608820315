/* eslint-disable react/no-multi-comp */
import CheckBox36 from '@sats-group/icons/36/checkbox';
import React from 'react';

import ArticleCard from '../../components/article-card/article-card';
import CleanHeader from '../../components/clean-header/clean-header';
import Expander from '../../components/expander/expander';
import Message from '../../components/message/message';
import Price from '../../components/price/price';
import Text from '../../components/text/text';

import type { CheckoutConfirmation as Props } from './checkout-confirmation.types';
import type { Summary as SummaryProps } from './checkout-confirmation.types';

const Summary: React.FC<SummaryProps> = ({ rows, price, priceLabel }) => (
  <div
    className="checkout-confirmation__summary"
    data-testId="confirmation-summary"
  >
    <div className="checkout-confirmation__summary-rows">
      {rows.map(({ label, value }) => (
        <div className="checkout-confirmation__summary-row">
          <Text>{label}</Text>
          <Text theme={Text.themes.medium}>{value}</Text>
        </div>
      ))}
    </div>
    <div className="checkout-confirmation__summary-price">
      <Text>{priceLabel}</Text>
      <Price {...price} />
    </div>
  </div>
);

const CheckoutConfirmation: React.FC<Props> = ({
  header,
  information,
  messages,
  more,
  summary,
  title,
}) => (
  <div className="checkout-confirmation">
    <CleanHeader {...header} />
    <div className="checkout-confirmation__title-wrapper">
      <div className="checkout-confirmation__icon">
        <CheckBox36 />
      </div>
      <Text
        className="checkout-confirmation__title"
        theme={Text.themes.emphasis}
        size={Text.sizes.header2}
        variant={Text.variants.hero}
      >
        {title}
      </Text>
    </div>
    <div className="checkout-confirmation__messages">
      {messages.map(entry => (
        <div key={entry.text}>
          <Message {...entry} />
        </div>
      ))}
    </div>
    <div className="checkout-confirmation__content">
      {information ? (
        <div>
          <Text>{information}</Text>
        </div>
      ) : null}

      {summary ? (
        <div>
          <Expander {...summary} itemRenderer={Summary} />
        </div>
      ) : null}
      <div className="checkout-confirmation__more">
        {more.map(article => (
          <div>
            <ArticleCard {...article} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default CheckoutConfirmation;
