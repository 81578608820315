export enum BlockType {
  ArticleCardCarousel = 'article-card-carousel',
  ArticleCardComponentList = 'article-card-component-list',
  ContentElementCarousel = 'content-element-carousel',
  ContentElementComponentList = 'content-element-component-list',
  CTABanner = 'cta-banner',
  Expander = 'expander',
  NavigationCardCarousel = 'navigation-card-carousel',
  NavigationCardComponentList = 'navigation-card-component-list',
  ProductOptionCardCarousel = 'product-option-cards-carousel',
  ProductOptionCardComponentList = 'product-option-cards-component-list',
  SplitInfoModule = 'split-info-module',
  TextBlock = 'text-block',
  TextBlockCarousel = 'text-block-carousel',
  TextBlockComponentList = 'text-block-component-list',
  MembershipsBlock = 'memberships-block',
}
