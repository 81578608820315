import type { FC } from 'react';
import AboutFreshPage from './pages/about-fresh-page/about-fresh-page';
import ArticlePage from './pages/article-page/article-page';
import ArticlesPage from './pages/articles-page/articles-page';
import CheckoutAboutYou from './pages/checkout-about-you/checkout-about-you';
import CheckoutConfirmation from './pages/checkout-confirmation/checkout-confirmation';
import CheckoutMembership from './pages/checkout-membership/checkout-membership';
import CheckoutPayment from './pages/checkout-payment/checkout-payment';
import CheckoutSelectClub from './pages/checkout-select-club/checkout-select-club';
import CheckoutStatus from './pages/checkout-status/checkout-status';
import ClubListPage from './pages/club-list-page/club-list-page';
import ClubPage from './pages/club-page/club-page';
import ClubsMapPage from './pages/clubs-map-page/clubs-map-page';
import CustomerService from './pages/customer-service/customer-service';
import CustomerServiceCategory from './pages/customer-service-category/customer-service-category';
import DropInAboutYou from './pages/drop-in-about-you/drop-in-about-you';
import DropInConfirmation from './pages/drop-in-confirmation/drop-in-confirmation';
import DropInPayment from './pages/drop-in-payment/drop-in-payment';
import DropInSelectClub from './pages/drop-in-select-club/drop-in-select-club';
import DropInStatus from './pages/drop-in-status/drop-in-status';
import EmployeeLogInPage from './pages/employee-log-in-page/employee-log-in-page';
import ErrorPage from './pages/error-page/error-page';
import GroupExerciseTypePage from './pages/group-exercise-type-page/group-exercise-type-page';
import GroupExerciseTypesPage from './pages/group-exercise-types-page/group-exercise-types-page';
import LandingPage from './pages/landing-page/landing-page';
import MemberCareFormPage from './pages/member-care-form-page/member-care-form-page';
import PersonalTrainerPage from './pages/personal-trainer-page/personal-trainer-page';
import PersonalTrainersPage from './pages/personal-trainers-page/personal-trainers-page';
import PersonalTrainingPage from './pages/personal-training-page/personal-training-page';
import RootPage from './pages/root-page/root-page';
import WaitingArea from './pages/waiting-area/waiting-area';
import WebAdminPage from './pages/web-admin-page/web-admin-page';
import WorkoutOfferPage from './pages/workout-offer-page/workout-offer-page';

/** This object is automatically generated. */
export const pageComponents: Record<string, FC> = {
  AboutFreshPage,
  ArticlePage,
  ArticlesPage,
  CheckoutAboutYou,
  CheckoutConfirmation,
  CheckoutMembership,
  CheckoutPayment,
  CheckoutSelectClub,
  CheckoutStatus,
  ClubListPage,
  ClubPage,
  ClubsMapPage,
  CustomerService,
  CustomerServiceCategory,
  DropInAboutYou,
  DropInConfirmation,
  DropInPayment,
  DropInSelectClub,
  DropInStatus,
  EmployeeLogInPage,
  ErrorPage,
  GroupExerciseTypePage,
  GroupExerciseTypesPage,
  LandingPage,
  MemberCareFormPage,
  PersonalTrainerPage,
  PersonalTrainersPage,
  PersonalTrainingPage,
  RootPage,
  WaitingArea,
  WebAdminPage,
  WorkoutOfferPage,
};
